import { get, values } from 'lodash';
import ReactDOM from 'react-dom';
import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Col,
  Csku,
  Popup,
  Row,
  XIcon,
  ArrowIcon as StyledArrowIcon,
  ChevronIcon as StyledChevronIcon,
} from '@commonsku/styles';

import { window, document } from '../../../global';
import { setFilter } from '../../../redux/gallery';

import {
  createCancelEditCartItem,
  createDeleteCartItem,
  createEditCartItem,
  createEditItem,
  createSaveCartItem,
  createUpdateBreakdown,
} from '../../../actions/shop';
import { getCompanyData, getFullCart } from '../../../selectors';

import {
  TEMPLATE_SIDEBAR_WIDTH,
} from '../helpers';
import { SmallOnly, Medium } from '../../ScreenSize';
import Header from '../Header';
import Footer from '../Footer';
import Tile, { DEFAULT_TILE_HEIGHT, DEFAULT_TILE_PADDING } from '../../gallery/Tile';
import Grid from '../../gallery/Grid';
import ItemComments from '../../gallery/ItemComments';
import { ArrowIcon, DetailMenu, GalleryItemInfo, InfoButtons, SlideShow } from '.';
import GridInline from '../../gallery/v2/GridInline';
import TileInline from '../../gallery/v2/TileInline';
import Sidebar from '../Sidebar';
import { withRouter } from '../../helpers';
import Filter from '../../gallery/Filter';
import { PosterThemeBanner } from './PosterThemeBanner';
import { publicViewContext, screenChangeEvents } from '../../../context/PublicViewProvider';
import { useContainerScroll } from '../../../hooks';
import { ScrollContainer } from '../../../hooks/useContainerScroll';

export const DEFAULT_COLOR = '#5ca3b6';

const ITEM_PREVIEW_V2_CONTAINER_STYLE = {
  width: '100%',
  border: 'none',
  boxShadow: 'none',
  background: '#fff',
  margin: 0,
  flexShrink: 0,
  minWidth: '100%',
};

const PageNavIcon = ({
  template_color,
  onClick,

  left = null,
  right = null,
  direction = 'left',
}) => (
  <Csku as="span"
    forceStyles
    onClick={onClick}
    style={{
      xs: {
        color: template_color,
        backgroundColor: 'transparent',
        padding: '15px',
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
        cursor: 'pointer',
        zIndex: 4,
        textAlign: 'center',
        ...(left !== null ? { left: left, } : {} ),
        ...(right !== null ? { right: right } : {} ),
      },
    }}
  >
    <StyledChevronIcon direction={direction} color={template_color} height={80} width={80} />
    <div style={{
      fontFamily: 'var(--font-family-regular)',
      fontSize: 14,
      verticalAlign: 'middle',
      width: 80,
      padding: 0,
      display: 'block',
    }}>{direction === 'left' ? 'Previous' : 'Next'} Product</div>
  </Csku>
);

const InlineDetailItemContainer = ({ children, productInDropdown, template_color, style={}, ...props }) => {
  /**
   * @type {React.RefObject<HTMLDivElement>}
   */
  const ref = useRef(null);
  const {
    canScrollDown,
    canScrollUp,
    scrollDown,
    scrollUp,
  } = useContainerScroll(ref, [productInDropdown, template_color]);

  return (
    <div {...props} ref={ref} style={{
      ...(productInDropdown ? { height: 672-56, overflowY: 'auto', } : {}),
      ...style,
    }}>
      {children}
      {ref.current ? <>
        <ScrollContainer
          isUp
          onClick={scrollUp}
          canScroll={canScrollUp()}
          color={template_color}
          width={ref.current.clientWidth}
          zIndex={99999}
          top={ref.current.offsetTop - 18}
          right={24}
          style={{ position: 'absolute' }}
        />

        <ScrollContainer
          isUp={false}
          onClick={scrollDown}
          canScroll={canScrollDown()}
          color={template_color}
          width={ref.current.clientWidth}
          zIndex={99999}
          top={ref.current.clientHeight + ref.current.offsetTop - 38 - 10}
          right={24}
          style={{ position: 'absolute' }}
        />
      </> : null}
    </div>
  );
};

export class ShopGallery extends Component {
  static contextType = publicViewContext;
  /**
   * @type {import('../../../context/PublicViewProvider').TPublicViewContext}
   */
  context;

  constructor(props) {
    super(props);
    this.onAddItem = this.onAddItem.bind(this);
    this.onClickBack = this.onClickBack.bind(this);
  }

  componentDidMount() {
    const {
      galleryRef,
      handleResize,
      handleScreenchange,
      setGalleryDimensions,
    } = this.context;

    window.addEventListener('resize', handleResize);
    screenChangeEvents.forEach(e => {
      document.addEventListener(e, handleScreenchange);
    });

    const elem = galleryRef.current;
    if (elem) {
      setGalleryDimensions(s => {
        if (elem.clientWidth === s.galleryWidth) {
          return s;
        }
        return { ...s, galleryWidth: elem.clientWidth };
      });
    }
  }

  componentWillUnmount() {
    const {
      pause,
      handleResize,
      handleScreenchange,
    } = this.context;

    window.removeEventListener('resize', handleResize);
    screenChangeEvents.forEach(e => {
      document.removeEventListener(e, handleScreenchange);
    });

    pause();
  }

  renderDetailMenu(item) {
    const { showComments = true,  } = this.props;
    const {
      galleryControls: { fullscreen },
      publicViewTemplate: {
        hasSidebar,
        productInPopup,
        productInDropdown,
      },
      onSelectItem,
      onSelectPage,
    } = this.context;

    if (!item) { return null; }

    return (
      <DetailMenu
        item={item}
        fullscreen={fullscreen}
        productInPopup={productInPopup}
        productInDropdown={productInDropdown}
        onSelectItem={onSelectItem}
        onSelectPage={onSelectPage}
        showComments={showComments}
        hasSidebar={hasSidebar}
      />
    );
  }

  renderDetail() {
    const {
      fullscreenRoot,
      productPopupRoot,
      selectedItemId: selected,
      galleryControls: { fullscreen },
      publicViewEntity: {
        entityOrder: { items },
        entityIsFree
      },
      publicViewTemplate: {
        hasSidebar,
        templateColor: template_color,
        productInPopup,
        productInDropdown,
      },
      onSelectItem,
      onSelectNextItem,
      onSelectPreviousItem,
      getDisplayItems,
    } = this.context;

    const item = items.filter(i => 1 !== +i.hidden && i.item_id === selected)[0];
    const style = fullscreen ? {
      position: 'relative',
      width: '75%'
    } : {
      position: 'relative',
      background: 'white',
      minHeight: hasSidebar ? 'calc(100vh - 4px)' : '100%',
      height:  hasSidebar ? '100%' : 'calc(100vh - 78px)',
    };
    const displayItems = getDisplayItems();
    const anyExtraImages = displayItems.reduce((t, i) => t || i.images.length > 1, false);
    const popupHeight = productPopupRoot.current
      ? productPopupRoot.current.getBoundingClientRect().height - (anyExtraImages > 0 ? 150 : 40)
      : 300;
    const Child = (<>
      {this.renderDetailMenu(item)}
      <Csku
        className="row collapse"
        style={{ maxWidth: '100%', }}
        sx={{
          xs: { marginLeft: 0, marginRight: 0, },
          md: { marginLeft: productInPopup ? 0 : 50, marginRight: 50, },
        }}
      >
        {this.renderSlideShow(item, popupHeight-100)}
        {this.renderMedium(item)}
        {this.renderSmall(item)}
      </Csku>
    </>);

    return (
      <Csku forceStyles ref={fullscreenRoot} style={style} sx={{
        xs: { height: 'calc(100% - 78px - 80px)', top: 78, marginBottom: 158 },
        sm: { height: 'calc(100% - 78px - 80px)', top: 78, marginBottom: 80 },
        md: { height: 'calc(100vh - 78px)', top: 0, marginBottom: 0 },
      }}>
        <Medium>
          {!productInPopup && !productInDropdown && <PageNavIcon
            direction='left'
            onClick={onSelectPreviousItem}
            template_color={template_color}
            left={0}
          />}
          {!productInPopup
            ? Child
            : <Popup
              noHeader
              ref={productPopupRoot}
              className='popup shop-item-popup'
              contentClassName='shop-item-popup-content'
              onClose={() => entityIsFree !== 1 && onSelectItem(null)}
            >
              <div style={{ position: 'relative', height: '100%' }}>
                <div style={{
                  zIndex: 9,
                  width: '97%',
                  position: 'absolute',
                  textAlign: 'right',
                  top: 0,
                  right: 0,
                }}>
                  <XIcon style={{ cursor: 'pointer' }} onClick={() => onSelectItem(null)} />
                </div>
                {Child}
              </div>
            </Popup>}
          {!productInPopup && !productInDropdown && <PageNavIcon
            direction='right'
            onClick={onSelectNextItem}
            template_color={template_color}
            right={0}
          />}
        </Medium>
        <SmallOnly>
          {Child}
          {selected && <div style={{
            width: '100%',
            background: '#fff',
            position: 'fixed',
            zIndex: 99,
            bottom: 0,
            height: 80,
            padding: 16,
          }}>
            <ArrowIcon
              direction='left'
              onClick={onSelectPreviousItem}
              template_color={template_color}
              left={0}
              xsSize='2em'
            >
              <div style={{
                fontFamily: 'var(--font-family-regular)',
                fontSize: 14,
                verticalAlign: 'middle',
                paddingLeft: 14,
                width: 68,
                display: 'inline-block',
              }}>Previous Product</div>
            </ArrowIcon>
            <div></div>
            <ArrowIcon
              direction='right'
              onClick={onSelectNextItem}
              template_color={template_color}
              right={0}
              xsSize='2em'
            >
              <div style={{
                fontFamily: 'var(--font-family-regular)',
                fontSize: 14,
                verticalAlign: 'middle',
                paddingRight: 14,
                width: 68,
                display: 'inline-block',
              }}>Next Product</div>
            </ArrowIcon>
          </div>}
        </SmallOnly>
      </Csku>
    );
  }

  renderSmall(item) {
    const { page } = this.context;
    if (!item) { return null; }

    return (
      <SmallOnly>
        <div>
          {'comment' !== page ? this.renderInfo(item, { background: '#fff' }) : null}
          {'comment' !== page ? this.renderInfoButtons(item, { paddingLeft: '1rem', paddingRight: '1rem' }) : null}
          {'comment' === page ? this.renderComments(item) : null}
        </div>
      </SmallOnly>
    );
  }

  renderInfoButtons(item, style) {
    const { showComments = true } = this.props;
    const { onSelectPage } = this.context;

    return (
      <InfoButtons
        item={item}
        style={style}
        showComments={showComments}
        onSelectPage={onSelectPage}
      />
    );
  }

  renderAddItemButton(item) {
    const { onAddItem, onRemoveItem, showItemCount = true } = this.props;
    const {
      publicViewEntity: {
        entityIsFree,
        entityAggregate,
        entityBuyInventory,
      },
      publicViewTemplate: { templateColor: template_color },
    } = this.context;
    if (!item) { return null; }
    const visible_options = item.options.filter(o => 0 == o.hidden && 0 != o.total_units);

    if(!visible_options.length && entityAggregate == 0) {
      return null;
    }

    const countStyle = {
      background: 'white',
      color: template_color,
      padding: '0.5rem 0.75rem',
      borderRadius: '1rem',
      marginLeft: '0.2rem',
      fontWeight: 'bold'
    };

    const onClick = e => {
      e.stopPropagation();
      if (showItemCount || !item.quantity) {
        onAddItem(item.item_id, item.parent_type);
      } else {
        onRemoveItem(item.item_id);
      }
    };

    const disabled = entityBuyInventory && item.item_skus.length === 0 && (item.item_colors.length < 1 || item.item_sizes.length < 1);
    if (disabled) {
      return (
        <p style={{ color: '#BF3F69', fontWeight: 'bold' }}>Unable to buy inventory items without at least one size and color.</p>
      );
    }

    return (
      <button className="button expanded" style={{ height: '3rem', background: template_color }} onClick={onClick}>
        {entityIsFree == 1 ? item.quantity ?
          <span>Selected</span> :
          <span>Select</span> : (item.quantity ?
          <span>Added <span style={countStyle}>{showItemCount ? item.quantity : <i className="fi-check"></i>}</span></span> :
          <span>Add</span>)}
      </button>
    );
  }

  renderCommentButton(item) {
    const { showComments = true } = this.props;
    const {
      onSelectPage,
      publicViewTemplate: { templateColor: template_color },
    } = this.context;

    if (!showComments) {
      return null;
    }
    if (!item) { return null; }

    const commentCount = Object.values(item.comments).length;

    const countStyle = {
      background: 'white',
      color: template_color,
      padding: '0.5rem 0.75rem',
      borderRadius: '1rem',
      marginLeft: '0.2rem',
      fontWeight: 'bold'
    };

    return (
      <button
        className="button expanded"
        style={{ height: '3rem', marginLeft: '0.5rem' }}
        onClick={e => onSelectPage('comment')}
      >
        Comments <span style={countStyle}>{commentCount}</span>
      </button>
    );
  }

  renderMedium(item) {
    const { showComments = true } = this.props;
    const {
      page,
      galleryControls: { fullscreen },
      publicViewTemplate: {
        hasSidebar,
        productInPopup,
      },
      onSelectPage,
    } = this.context;

    if (fullscreen) {
      return null;
    }
    if (!item) { return null; }

    const elemHeightSubtract = 7;
    const style = {
      height: productInPopup || hasSidebar ? '100%' : `calc((100vh - ${elemHeightSubtract}px) - 72px)`,
      boxShadow: '0 0 10px #00000021',
      padding: '20px',
      position: 'absolute',
      top: '0',
      right: '0',
      ...(productInPopup ? {
        paddingTop: 40,
        paddingRight: 0,
      } : {
        paddingRight: 80,
        overflowY: 'auto',
      }),
    };

    return (
      <Medium>
        <div className="medium-5 columns" style={style}>
          {'slide-show' === page || 'info' === page || !showComments ?
            <div className="row collapse" style={{
              // height: productInPopup ? '100%' : `calc((100vh - ${elemHeightSubtract}px) - 6rem)`,
              overflowY: 'auto',
              marginBottom: '1rem',
              ...(productInPopup ? { height: '100%' } :{}),
            }}>
              {this.renderInfo(item)}
            </div> :
            <div className="row small-12 columns" style={{ padding: 0 }}>
              <button className="button secondary" onClick={e => onSelectPage('info')}>&laquo;&nbsp; Product Description</button>
            </div>}
          {'slide-show' === page || 'info' === page || !showComments ?
            this.renderInfoButtons(item, { paddingRight: '0' }) :
            <div className="row collapse" style={{
              height: productInPopup || hasSidebar ? '100%' : `calc(100vh - ${elemHeightSubtract}px)`,
            }}>
              {this.renderComments(item, { height: productInPopup || hasSidebar ? '100%' : 'calc(100vh - 220px - 13rem)', overflowY: 'auto' })}
            </div>}
        </div>
      </Medium>
    );
  }

  renderSlideShow(item, popupHeight=300) {
    const {
      page,
      extraImageId,
      selectedItemId: selected,
      galleryControls: { fullscreen, playing },
      publicViewEntity: { entityOrder: order },
      publicViewTemplate: {
        productInPopup,
        productInDropdown,
        productInCarousel,
      },
      play,
      pause,
      onSelectImage,
      exitFullscreen,
      requestFullscreen,
    } = this.context;

    return (
      <SlideShow
        item={item}
        order={order}
        page={page}
        selected={selected}
        fullscreen={fullscreen}
        extraImageId={extraImageId}
        productInPopup={productInPopup}
        productInDropdown={productInDropdown}
        productInCarousel={productInCarousel}
        playing={playing}
        pause={pause}
        play={play}
        onSelectImage={onSelectImage}
        exitFullscreen={exitFullscreen}
        requestFullscreen={requestFullscreen}
        popupHeight={popupHeight}
      />
    );
  }

  renderInfo(item, style) {
    const {
      params,
      isPreviewing,
    } = this.props;
    const {
      loading,
      publicViewTemplate: {
        isPoster,
        productInPopup,
      },
      onSelectItem,
      onClickCart,
    } = this.context;
    const {
      state: { cart = {items: []} },
      onEditCartItem,
      onSaveCartItem,
      onDeleteCartItem,
      onUpdateBreakdown,
      onCancelEditCartItem,
    } = this.context.galleryCart;

    return (
      <GalleryItemInfo
        item={item}
        cart={cart}
        params={params}
        loading={loading}
        style={style}
        onClickCart={onClickCart}
        onEditCartItem={onEditCartItem}
        onCancelEditCartItem={onCancelEditCartItem}
        onDeleteCartItem={onDeleteCartItem}
        onSaveCartItem={onSaveCartItem}
        onUpdateBreakdown={onUpdateBreakdown}
        isPreviewing={isPreviewing}
        onAddItem={this.onAddItem}
        itemNameFont={isPoster ? 'Bebas Neue' : ''}
      />
    );
  }

  onAddItem() {
    if (!this.context.publicViewTemplate.productInPopup &&
        +this.context.publicViewEntity.entityIsFree !== 1
    ) {
      return;
    }
    this.context.onSelectItem(null);
  }

  renderComments(item, style) {
    const { showComments = true } = this.props;

    if (!showComments) {
      return null;
    }

    return (
      <ItemComments item={item} style={style} />
    );
  }

  renderItemPreviewV2InlineDetail(index, selectedIndex, item, fullscreen) {
    if (index !== selectedIndex) return null;
    const { galleryDimensions: { galleryWidth } } = this.context;
    const singleItemWidth = (galleryWidth - 2 * 24) / 3;
    const arrowPositionLeft = Math.floor(singleItemWidth / 2 + index % 3 * singleItemWidth) - 5;
    const flexOrder = selectedIndex + (3 - selectedIndex % 3);

    return (
      <>
        <Medium>
          <Box
            id={`item-${item.item_id}`}
            bg="#F3F3F3"
            style={{
              marginTop: 51,
              position: "relative",
              minWidth: "100%",
              borderRadius: "5px",
              order: flexOrder,
            }}
          >
            <Csku as="div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="51"
                height="27"
                viewBox="0 0 51 27"
                fill="none"
                style={{
                  position: "absolute",
                  top: "-27px",
                  left: `${arrowPositionLeft}px`,
                }}
              >
                <path d="M24 0L0 27H51L24 0Z" fill="#F3F3F3" />
              </svg>
              <Row className="small-12 columns">
                {!fullscreen && (
                  <Col xs padded>
                    {this.renderDetailMenu(item)}
                  </Col>
                )}
                <Col xs sm={6} padded>
                  {this.renderSlideShow(item)}
                </Col>
                <Col xs sm={6} padded>
                  {this.renderInlineDetailItem(item)}
                </Col>
              </Row>
            </Csku>
          </Box>
        </Medium>
      </>
    );
  }

  renderItemInlineDetail(index, total, width = 300, widthGroups = [], item_preview_version='1') {
    const {
      filter,
      selectedItemId: selected,
      galleryDimensions: { galleryWidth },
      galleryControls: { fullscreen },
      publicViewEntity: { entityOrder: { items }, },
      publicViewTemplate: {
        productListStyle,
        productInDropdown,
      },

      getDisplayItems,
    } = this.context;

    const item = items.filter(i => 1 !== +i.hidden && i.item_id === selected)[0];
    const display_items = getDisplayItems(filter === 'shop' ? '' : filter);
    const selectedIndex = (display_items || []).findIndex(v => v.item_id === selected);
    if (!selected) {
      return null;
    }

    if (item_preview_version === '2') {
      return this.renderItemPreviewV2InlineDetail(index, selectedIndex, item, fullscreen);
    }

    const mywithGroup = widthGroups.find(wg => {
      return Boolean(wg.find(w => w.index === selectedIndex));
    });
    let marginLeft = 0;
    if (productListStyle !== 'GRID') {
      if (!mywithGroup || mywithGroup[mywithGroup.length-1].index !== index) {
        return null;
      }

      marginLeft = mywithGroup.reduce(
        (acc, w) => acc + (w.index < selectedIndex ? w.width : 0),
        0
      ) + (width / 2.75);
    } else {
      const divisor = galleryWidth >= 1024 ? 4 : (galleryWidth >= 640 ? 3 : 1);
      marginLeft = (
        (galleryWidth / divisor)  * (selectedIndex % divisor)
      ) + (
        ((galleryWidth / divisor) - (galleryWidth * 0.02)) / 2
      );

      const rowIdx = Math.floor(selectedIndex / divisor);
      let isSelected = index === ((rowIdx+1) * divisor)-1;
      if ((rowIdx+1) * divisor > total) {
        isSelected = index === total;
      }
      if (!isSelected) { return null; }
    }

    return (
      <Box padded style={{paddingLeft: "1%", paddingRight: "1%"}}>
        <div style={{ marginTop: 20 }}>
          <Row className="small-12 columns" bg="#fff"
            style={{
              boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)',
              borderRadius: 5,
              border: '1px solid rgba(0, 0, 0, 0.2)',
              ...(productListStyle !== 'GRID' ? {
                marginTop: 18,
              } : {}),
            }}
          >
            {productInDropdown && <div
              className="arrow-up"
              style={{
                width: 0,
                height: 0,
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderBottom: '15px solid #fff',
                marginTop: '-15px',
                marginLeft: `${marginLeft}px`,
              }}
            ></div>}
            {!fullscreen && <Col xs padded>
              {this.renderDetailMenu(item)}
            </Col>}
            <Col xs sm={6} padded>
              {this.renderSlideShow(item)}
            </Col>
            <Col xs sm={6} padded>
              {this.renderInlineDetailItem(item)}
            </Col>
          </Row>
        </div>
      </Box>
    );
  }

  renderInlineDetailItem(item) {
    const { showComments = true } = this.props;
    const { page, onSelectPage, publicViewTemplate: { productInDropdown, templateColor } } = this.context;
    if (!item) { return null; }

    return (
      <InlineDetailItemContainer
        productInDropdown={productInDropdown}
        template_color={templateColor}
      >
        <Row>
          <Col xs padded>
          {'slide-show' === page || 'info' === page || !showComments
            ? this.renderInfo(item)
            : <Button onClick={e => onSelectPage('info')}>
                &laquo;&nbsp; Product Description
              </Button>}
          </Col>
          <Col xs padded>
          {'slide-show' === page || 'info' === page || !showComments
            ? this.renderInfoButtons(item, { paddingRight: '0' })
            : this.renderComments(item, { height: 'calc(100vh - 220px - 13rem)', overflowY: 'auto' })}
          </Col>
        </Row>
      </InlineDetailItemContainer>
    );
  }

  getWidthGroups(galleryWidth, widths) {
    let offset = galleryWidth;
    let temp = [];
    let widthGroups = [];
    let i = 0;
    let added = 0;
    for (const w of widths) {
      if (offset === 0) {
        widthGroups.push(temp);
        added += temp.length;
        offset = galleryWidth;
        temp = [];
      }
      offset = offset-w;
      temp.push({ index: i, width: w });
      i += 1;
    }

    //  added any left out
    widthGroups.push(
      widths.slice(added)
        .map((w, i) => ({ index: i+added, width: w }))
    );
    return widthGroups;
  }

  getWidths(items, fullWidth, fullHeight, itemWidth = null) {
    const { itemSizes } = this.context;
    const maxRows = Math.floor((fullHeight - 130 - 102) / DEFAULT_TILE_HEIGHT);
    return items.reduce((rows, item) => {
      const lastRow = rows[rows.length - 1];
      const rowWidth = lastRow.reduce((t, w) => t + w, 0);
      const scale = DEFAULT_TILE_HEIGHT / (itemSizes[item.item_id]?.height ?? DEFAULT_TILE_HEIGHT);

      if (!itemWidth) {
        itemWidth = (itemSizes[item.item_id]?.width ?? 100) * scale + DEFAULT_TILE_PADDING * 2;
      }
      if (rowWidth + itemWidth > fullWidth + itemWidth / 2) {
        return rows.concat([[ itemWidth ]]);
      } else {
        return rows.slice(0, -1).concat([lastRow.concat(itemWidth)]);
      }
    }, [[]]).reduce((result, row, index, rows) => {
      const rowWidth = row.reduce((t, w) => t + w, 0);
      if (rowWidth < fullWidth && index === rows.length - 1) {
        return result.concat(row);
      }
      const allButLast = row.slice(0, -1).map(w => Math.floor(w / rowWidth * fullWidth));
      return result.concat(allButLast.concat(fullWidth - allButLast.reduce((t, w) => t + w, 0)));
    }, []);
  }

  renderProductList() {
    const { onAddItem, } = this.props;
    const {
      filter,
      galleryRef,
      selectedItemId: selected,
      galleryDimensions: { windowHeight, galleryWidth },
      publicViewEntity: {
        entityOrder: { currency_symbol, hide_pricing='0', },
        entityIsFree,
        entityAggregate,
        entityBuyInventory,
        entityShowInventory,
      },
      publicViewTemplate: {
        isPoster,
        templateData,
        templateColor: template_color,
        productInDropdown,
        productListStyle,
      },

      getDisplayItems,
      onSelectItem,
      onImageLoad,
    } = this.context;
    const display_items = getDisplayItems(filter === 'shop' ? '' : filter);
    const firstItemId = display_items?.[0]?.item_id;
    let galleryWindowWidth = galleryWidth;
    let itemWidth = null;
    if (firstItemId) {
      const elem = document.getElementById('item-' + firstItemId);
      const elemRect = elem?.getBoundingClientRect?.();
      if (elemRect && elemRect?.width) {
        itemWidth = elemRect.width;
        const itemCountPerRow = Math.floor(galleryWidth / itemWidth);
        galleryWindowWidth = itemCountPerRow * itemWidth;
      }
    }

    const widths = this.getWidths(display_items, galleryWindowWidth, windowHeight, itemWidth);
    const widthGroups = this.getWidthGroups(galleryWindowWidth, widths);

    const GridComponent = productListStyle === 'GRID' ? (productInDropdown ? GridInline : Grid) : null;
    const TileComponent = productListStyle !== 'GRID' ? (productInDropdown ? TileInline : Tile) : null;

    const ProductListItemComponent = isPoster ? Grid : productListStyle === 'GRID' ? GridComponent : TileComponent;
    const productListItems = display_items.map((item, index) => {
      const selectedIndex = (display_items || []).findIndex(v => v.item_id === selected);
      const selectedDetailPanelIndex = selectedIndex + ( 3 - selectedIndex % 3);
      const itemFlexOrder = !selected ? index : (index < selectedDetailPanelIndex ? index : index + 1);

      return (
        <React.Fragment key={'grid-item-' + item.item_id}>
          <ProductListItemComponent
            key={item.item_id}
            id={item.item_id}
            item={item}
            currency_symbol={currency_symbol}
            onClick={() => onSelectItem(selected === item.item_id ? null : item.item_id)}
            onAddItem={onAddItem}
            showItemCount={true}
            is_shop_free={entityIsFree}
            onImageLoad={onImageLoad(item.item_id)}
            template_color={template_color}
            index={index}
            total={display_items.length - 1}
            aggregate={entityAggregate == 1}
            buyInventory={!!entityBuyInventory}
            showInventory={!!entityShowInventory || !!entityBuyInventory}
            item_preview_version={templateData.item_preview_version || '1'}
            itemFlexOrder={itemFlexOrder}
            hide_pricing={+(hide_pricing ?? "0")}
            gridNumberTop={isPoster ? 12 : null}
            gridNumberLeft={isPoster ? 8 : null}
            useV2
          />
          {productInDropdown ? this.renderItemInlineDetail(
            index,
            display_items.length - 1,
            widths[index],
            widthGroups,
            templateData.item_preview_version || '1'
          ) : null}
        </React.Fragment>
      );
    });

    if (isPoster) {
      return (
        <React.Fragment>
          <Csku as={Row} forceStyles ref={galleryRef} style={ITEM_PREVIEW_V2_CONTAINER_STYLE}>
            {productListItems}
          </Csku>
          <Csku as={Row}>
            {this.renderFooter()}
          </Csku>
        </React.Fragment>
      );
    }

    return(
      <React.Fragment>
        <div ref={galleryRef} className="row" style={{
          marginLeft: DEFAULT_TILE_PADDING,
          marginRight: DEFAULT_TILE_PADDING,
          maxWidth: '100%',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}>{productListItems}</div>
        {this.renderFooter()}
      </React.Fragment>
    );
  }

  renderGallery() {
    const {
      filter,
      onSelectItem,
      publicViewEntity: {
        entityOrder: { items },
      },
      publicViewTemplate: {
        isPoster,
        template_data,
        templateColor: template_color,
        template: {
          public_view_template_properties,
        },
        getPosterCategories,
        getShopCategoryWithImages
      },
    } = this.context;

    const showFilter = !isPoster;
    let category;

    let orderItems = items.filter(i => 1 !== +i.hidden);

    if (isPoster) {
      const posterCategories = getPosterCategories(orderItems);
      category = posterCategories.find((c) => c.item_id === (filter === 'shop' ? 'ALL_PRODUCTS' : filter));
    } else {
      const defaultCategoryImage = get(template_data, ['ALL-PRODUCTS-IMAGE-MAIN', 'value']) ||
        get(values(public_view_template_properties)
            .find(v => 'ALL-PRODUCTS-IMAGE-MAIN' === v.public_view_template_property_name),
          ['ALL-PRODUCTS-IMAGE-MAIN']);

      if (filter === "shop") {
        if (defaultCategoryImage) {
          category = {
            item_name: "All Products",
            categoryImages: [defaultCategoryImage],
          };
        }
      } else {
        category = getShopCategoryWithImages(orderItems).find((ii) => ii.item_id === filter);
      }
    }

    const showCategoryImage =
      isPoster && category?.categoryImages &&
      category?.categoryImages.length > 0;

    return (
      <Csku forceStyles sx={{
        xs: { position: 'relative', marginTop: '80px' },
        sm: { marginTop: 0 },
      }}>
        {showFilter && <Filter
          filter={filter}
          items={orderItems}
          onFilter={(f) => {
            onSelectItem(null);
            this.props.dispatch(setFilter(f));
          }}
          template_color={template_color}
        />}
        {showCategoryImage && <PosterThemeBanner category={category} />}
        {this.renderProductList()}
      </Csku>
    );
  }

  onClickBack() {
    this.context.onSelectItem(null);
  }

  renderHeader() {
    const { hideIntro = false, isPreviewing = false, } = this.props;
    const { showCart, selectedItemId: selected, onClickCart, onSelectItem, publicViewEntity: { entityBuyInventory } } = this.context;
    return (
      <Header
        isEditable={false}
        buy_inventory={entityBuyInventory}
        showBackButton={selected}
        onClickBackButton={this.onClickBack}
        hideIntro={hideIntro}
        isPreviewing={isPreviewing}
      />
    );
  }

  renderFooter() {
    const { isPoster, } = this.context.publicViewTemplate;
    const { entityType } = this.context.publicViewEntity;

    return (
      <Footer isEditable={false}
        withTopBorder={!isPoster}
        centerAligned={isPoster}
        overrideFontStyle={isPoster}
        style={entityType === 'ORDER' ? { position: 'unset' } : {}}
      />
    );
  }

  renderExpired() {
    const {
      publicViewEntity: { entityOrder: { order_type } }
    } = this.context;

    return (
      <div style={{ position: 'relative' }}>
        <div className="row" style={{
          marginLeft: DEFAULT_TILE_PADDING * 2,
          marginRight: DEFAULT_TILE_PADDING * 2,
          marginTop: DEFAULT_TILE_PADDING * 2,
          height: 'calc((100vh - 78px) - 50px)',
          padding: '1rem',
          background: 'white',
          maxWidth: '100%',
        }}>
          <h3>This {order_type.toLowerCase()} has expired</h3>
          <p>Unfortunately, this {order_type.toLowerCase()} has expired and is no longer available.</p>
        </div>
        {this.renderFooter()}
      </div>
    );
  }

  renderWithSidebar() {
    const { shop, } = this.props;
    const {
      showCart,
      selectedItemId: selected,
      publicViewEntity: {
        entityOrder: { expired },
      },
      publicViewTemplate: {
        templateColor: template_color,
        productInPopup,
        productInDropdown,
        productInCarousel,
      },
      onSelectItem,
      onClickCart,
    } = this.context;
    const sidebarWidth = productInCarousel ? selected ? '0' : TEMPLATE_SIDEBAR_WIDTH : TEMPLATE_SIDEBAR_WIDTH;

    return (
      <div style={{ background: '#fff' }} className="shop">
        {selected && productInCarousel && ReactDOM.createPortal(
          <div style={{
            zIndex: 8,
            position: 'fixed',
            top: 10,
            left: 10,
            background: '#fff',
            height: 60,
            width: 60,
            borderRadius: '50%',
            textAlign: 'center',
            cursor: 'pointer',
          }} onClick={() => {
            onSelectItem(null);
          }}>
            <div style={{ height: '100%', marginTop: 12.5, }}>
              <StyledArrowIcon
                size='large'
                direction='left'
                color={template_color}
                style={{
                  left: '-5px',
                  top: '-5px',
                }}
              />
            </div>
          </div>,
          document.getElementById('root')
        )}
        <Csku forceStyles sx={{ xs: { width: '100%', }, md: { width: sidebarWidth, } }}>
          <Csku as={Row}
            style={{
              overflowY: 'auto',
              alignContent: 'flex-start',
              height: '100%',
            }}
            sx={{ xs: { width: '100%', }, sm: { width: sidebarWidth, position: 'fixed', } }}
          >
            <Sidebar
              shop={shop}
              isEditable={false}
              onClickCart={onClickCart}
              isCartOpen={showCart}
              isItemSelected={!!selected}
              changeCartStyleWhenItemSelected={productInCarousel}
            />
          </Csku>
        </Csku>
        <Csku forceStyles
          sx={{
              xs: { width: '100%' },
              sm: { width: `calc(100% - ${sidebarWidth}px)`, marginLeft: sidebarWidth, }
          }}
        >
          <Medium>
            {expired
              ? this.renderExpired()
              : (
                selected && !productInPopup && !productInDropdown
                  ? this.renderDetail()
                  : this.renderGallery()
              )}
            {selected && productInPopup ? this.renderDetail() : null}
          </Medium>
          <SmallOnly>
            {expired
              ? this.renderExpired()
              : (selected ? this.renderDetail() : this.renderGallery())}
          </SmallOnly>
        </Csku>
      </div>
    );
  }

  render() {
    const {
      selectedItemId: selected,
      publicViewEntity: { entityOrder: { expired }, },
      publicViewTemplate: {
        hasSidebar,
        productInPopup,
        productInDropdown,
      },
    } = this.context;

    if (hasSidebar) {
      return this.renderWithSidebar();
    }

    return (
      <div style={{ background: '#f2f2f2' }} className="shop">
        {this.renderHeader()}
        <Medium>
          {expired ? this.renderExpired() : (selected && !productInPopup && !productInDropdown ? this.renderDetail() : this.renderGallery())}
          {selected && productInPopup ? this.renderDetail() : null}
        </Medium>
        <SmallOnly>
          {expired ? this.renderExpired() : (selected ? this.renderDetail() : this.renderGallery())}
        </SmallOnly>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    default_margin: getCompanyData(state).default_margin || 40,
    showItemCount: true,
    showComments: false,
    initFilter: state.gallery.filter,
    filter: state.gallery.filter,
    editingCartItemId: state.temp.editingCartItemId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  dispatch: dispatch,
  onAddItem: (item_id) => dispatch(createEditCartItem(ownProps.params.shop_id, item_id,ownProps.is_shop_free)),
  onEditCartItem: (item_id) => dispatch(createEditItem(ownProps.params.shop_id, item_id, ownProps.is_shop_free, false)),
  onCancelEditCartItem: () => dispatch(createCancelEditCartItem()),
  onDeleteCartItem: item_id => dispatch(createDeleteCartItem(item_id, ownProps.params.shop_id)),
  onSaveCartItem: (item_id, updates) => dispatch(createSaveCartItem(item_id, updates)),
  onUpdateBreakdown: (item_id, color_id, size_id, sku_id, quantity) => {
    dispatch(createUpdateBreakdown(item_id, color_id, size_id, sku_id, quantity, ownProps.is_shop_free, ownProps.params.shop_id));
  },
});

const ConnectedShopGallery = withRouter(connect(mapStateToProps, mapDispatchToProps)(ShopGallery));
export default ConnectedShopGallery;
